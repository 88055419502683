export const wrapperStyle = () => {
    return { 
      background: "#F5F9FC",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      wordBreak: "break-word",
      height: '100vh'
    };
  };
  
  export const titleStyle = () => {
    return {
      textAlign: "center",
      color: "rgb(67, 171, 255)",
      fontSize: "24px",
      width: "100%",
      marginBottom: "0",
    };
  };
  
  export const subTitleStyle = () => {
    return {
      textAlign: "center",
      color: "#AAA",
      fontSize: "14px",
      width: "100%",
      padding: "0 1em 24px 1em",
    };
  };
  
  export const helpTextStyle = () => {
    return {
      fontSize: "14px",
      textAlign: "center",
      width: "100%",
      color: "#333",
      padding: "0 1em",
    };
  };
  
  export const componentStyle = () => {
    return {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      padding: "25px",
      paddingTop: "0px",
      width: "100%",
    };
  };
  
  export const boxStyle = () => {
    return {
      alignItems: "center",
      justifyContent: "center",
      flex: "1",
      minWidth: "300px",
      maxWidth: "400px",
      textAlign: "center"
    };
  };

  export const boxInnerStyle = () => {
    return {
      backgroundColor: "#fff",
      padding: "20px 20px 30px 20px",
      margin: "0px 20px 10px",
      borderRadius: "10px",
    };
  };
  
  
  export const titleWrapperStyle = () => {
    return {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    };
  };
  
  export const thumbnailWrapperStyle = () => {
    return {
      width: "36px",
      height: "36px",
    };
  };
  
  export const componentTitleStyle = () => {
    return {
      fontWeight: "600",
      padding: "10px",
      fontSize: 20,
      lineHeight: "30px",
      color: "#4a4a4a",
      margin: "0 0 20px 0",
    };
  };
  
  export const descWrapperStyle = () => {
    return {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    };
  };
  
  export const linkWrapperStyle = () => {
    return {
      borderTop: "0.3px solid #e8e8e8",
      padding: "16px",
      paddingBottom: "0px",
      display: "flex",
      margin: 0,
      alignItems: "center",
      justifyContent: "space-around",
      width: "100%",
    };
  };
  
  export const linkStyle = () => {
    return {
      color: "rgb(67, 171, 255)!important",
      fontWeight: 600,
      fontSize: "14px",
    };
  };

  export const fieldInputStyle = () => {
    return {
        width: '100%',
        border: '1px solid #c8c8c8',
        height: '40px',
        padding: '0 15px',
        marginBottom: '15px',
        '&:focus': {
            border: '1px solid red',
        }
      };
  }

  export const loginButtonStyle = () => {
      return {
          color: '#fff',
          background: '#00507D',
          borderRadius: '6px',
          padding: '10px 20px'
      }
  }
  
  