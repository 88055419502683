import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import axios from 'axios';
import {CometChat} from '@cometchat-pro/chat'
import { COMETCHAT_CONSTANTS } from './CONSTS'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "./assets/styles/global.css";
import './registerServiceWorker'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.headers.common.Accept = "application/json"

// adding token by interceptors
axios.interceptors.request.use(function (config) {
	const token = "Bearer " + store.getters.getUserToken
	config.headers.Authorization = token;
	return config;
});

// refresh token by using interceptors
let isRefreshing = false;

axios.interceptors.response.use(
	response => {
		return response;
	},
	async (err) => {
		const {
			config,
			response: { status, data }
		} = err;
		const originalRequest = config;
		if (store.getters.getUserToken === '') {
			//store.dispatch("logout")
			return Promise.reject(err);
		}

		if (originalRequest.url.includes("refresh-token")) {
			store.dispatch("logout")
			return Promise.reject(err);
		}

		if ( status == 500 || status == 400) {
			return Promise.reject(data)
		}

		if (status === 401 && data.message === "Unauthenticated.") {
			if (!isRefreshing) {
				isRefreshing = true;
				store
					.dispatch("refreshToken")
					.then(({ status }) => {
						if (status === 200 || status == 204) {
							isRefreshing = false;
						}
						originalRequest.headers.Authorization = "Bearer " + store.getters.getUserToken
						return axios(originalRequest)
					})
					.catch(error => {
						return Promise.reject(error);
					});
			}
		}
	}
);
var appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(COMETCHAT_CONSTANTS.REGION)
  .build();

CometChat.init(COMETCHAT_CONSTANTS.APP_ID, appSetting).then(() => {
	const app = createApp(App)
	app.use(router);
	app.use(ElementPlus);
	app.use(store);
	app.mount("#app");
    // createApp(App).use(router).use(ElementPlus).use(store).mount("#app");
});