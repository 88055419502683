import axios from "axios"
import router from '../../router/index'
import {CometChat} from '@cometchat-pro/chat'

const state = {
    user_token: localStorage.getItem('user_token') || '',
    expiresAt: localStorage.getItem('expiresAt') || '',
    isRefreshing: false,
    user: (localStorage.getItem('shifl.user_details')!==null) ? JSON.parse(localStorage.getItem('shifl.user_details')) : null,
    errors: '',
    forgetPasswordLoading: false,
    resetPasswordLoading: false,
    resetPasswordGlobalLoading: false
}

const getters = {
    getUser: (state) => state.user,
    getUserToken: state => state.user_token,
    getExpiresAt: state => state.expiresAt,
    getErrorMessage: state => state.errors,
    getforgetPasswordLoading: state => state.forgetPasswordLoading,
    getResetPasswordLoading: state => state.resetPasswordLoading,
    getResetPasswordGlobalLoading: state => state.resetPasswordGlobalLoading
}

const actions = {
    async login({
        commit
    }, payload) {
        
        commit("SET_ERROR_MESSAGE", "")
        await axios.post(`${process.env.VUE_APP_COMET_CHAT_MICROSERVICE_URL}/login`, payload, {
                withCredentials: true,
                credentials: 'include'
            })
            .then(res => {
                if (res.status === 200) {
                    console.log('res.data.authToken', res.data.auth_token);
                    CometChat.login(res.data.auth_token).then(
                        () => {
                            router.push({ name: "Home" });
                        }, error => {
                            router.push({ name: "Login" });
                            console.log("Login failed with exception:", { error });
                        }
                      );
                    
                }
            })
            .catch(err => {
                let errorMessage = (typeof err.response.data !== 'undefined' && typeof err.response.data.message !== 'undefined') ? err.response.data.message : 'Wrong username or password.'
                errorMessage = 'Wrong username or pssword.'
                commit("SET_ERROR_MESSAGE", errorMessage)
            })
    }
}
const mutations = {
    SET_RESET_PASSWORD_GLOBAL_LOADING: (state, payload) => {
        state.resetPasswordGlobalLoading = payload
    },
    SET_FORGET_PASSWORD_LOADING: (state, payload) => {
        state.forgetPasswordLoading = payload
    },
    SET_RESET_PASSWORD_LOADING: (state, payload) => {
        state.resetPasswordLoading = payload
    },
    SET_TOKEN: (state, payload) => {
        state.user_token = payload.token
        state.expiresAt = payload.expiresAt
    },
    SET_USER: (state, payload) => {
        state.user = payload
    },
    SET_ERROR_MESSAGE: (state, payload) => {
        state.errors = payload
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}