<template>
  <div>
    <div :style="styles.wrapper">
      <div
        :key="i"
        :style="styles.component"
        class="kitchen-sink__component"
      >
        <div
          :key="i"
          :style="styles.box"
          class="kitchen-sink__box"
        >
        <el-image
        style="width: 160px; height: 100px"
        src="images/logo.svg"
        fit="contain"
      ></el-image>
        <div :style="styles.boxInner">
          <div :style="styles.titleWrapper">
            <h2 :style="styles.componentTitle">Sign In to shifl</h2>
          </div>
          <el-form
            label-position="top"
            style="max-width: 460px"
            label-width="auto"
            :model="loginForm"
            ref="loginForm"
            size="large"
            :rules="rules"
          >
            <el-form-item label="Email Address" prop="email">
              <el-input v-model="loginForm.email" placeholder="e.g. abcdefghi@gmail.com" @input="onChangeEmail"></el-input>
              <span class="input-errors" v-for="(error, index) of v$.loginForm.email.$errors" :key="index">
                <span class="error-text">{{ error.$message }}</span>
              </span>
            </el-form-item>
            <el-form-item label="Password" prop="password">
              <el-input v-model="loginForm.password" type="password" placeholder="Type your password" @input="onChangePassword"></el-input>
              <span class="input-errors" v-for="(error, index) of v$.loginForm.password.$errors" :key="index">
                <span class="error-text">{{ error.$message }}</span>
              </span>
            </el-form-item>
            <el-button @click="submit('loginForm')" color="#0171a1" style="font-weight: normal; width: 100%;" width="100%" type="primary">{{ loginForm.loginBtnValue }}</el-button>
          </el-form>
            
          <div v-show="(getErrorMessage!=='')" cols="12" sm="12" class="flex">
            <label class="error-message">
                <span class="error-text">Sorry credential doesn’t match. Either email address or password is incorrect.</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

    <div :style="{ height: '32px' }"></div>
  </div>
</template>

<script>
import * as style from "./style";
import { mapGetters, mapActions } from "vuex";
import { CometChat } from "@cometchat-pro/chat";
import useVuelidate from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  data: () => ({
      valid: true,
      loginForm: {
          email: (localStorage.getItem('shifl.user_rememberme_email')!==null) ? localStorage.getItem('shifl.user_rememberme_email') : '',
          password: "",
          checkbox: (localStorage.getItem('shifl.user_rememberme_email')!==null) ? true : false,
          loading: false,
          loginBtnValue: "Sign In",
          loginBtnDefaultValue: "Sign In",
          loginBtnLoadingValue: "Signing in..."
      },
      loading: false,
      formProcessing: false,
      errors: [],
      loginSuccess: false
  }),
  validations () {
    return {
      loginForm:{
        email: { 
          required: helpers.withMessage('Email is required', required),
          email: helpers.withMessage('Enter valid email address', email) 
          },
        password: { 
          required: helpers.withMessage('Password is required', required)
        }
      }
    }
  },
  computed: {
      ...mapGetters(["getErrorMessage"]),
      styles() {
        return {
          wrapper: style.wrapperStyle(),
          title: style.titleStyle(),
          subTitle: style.subTitleStyle(),
          helpText: style.helpTextStyle(),
          component: style.componentStyle(),
          box: style.boxStyle(),
          boxInner: style.boxInnerStyle(),
          titleWrapper: style.titleWrapperStyle(),
          thumbnailWrapper: style.thumbnailWrapperStyle(),
          componentTitle: style.componentTitleStyle(),
          descWrapper: style.descWrapperStyle(),
          linkWrapper: style.linkWrapperStyle(),
          link: style.linkStyle(),
          fieldInput: style.fieldInputStyle(),
          loginButton: style.loginButtonStyle(),
        };
      },
  },
  methods: {
      ...mapActions(["login"]),
      async submit() {
        this.v$.loginForm.$validate()
        if (!this.v$.$error) { // if ANY fail validation
          if ( !this.loginForm.loading ) {
            //set loading state
            this.loginForm.loading = true
            this.loginForm.loginBtnValue = this.loginForm.loginBtnLoadingValue
            //attempt to login
            try {
                await this.login(this.loginForm)
                this.loginForm.loading = false
                this.loginForm.loginBtnValue = this.loginForm.loginBtnDefaultValue
                if (this.getErrorMessage == '') {
                    this.loginSuccess = true
                } else {
                    this.loginSuccess = false
                }
            } catch(e) {
                this.loginForm.loading = false
                this.loginForm.loginBtnValue = this.loginForm.loginBtnDefaultValue
                this.loginSuccess = false
            }
          }
        }
        
      },
      onChangeEmail (value){
        this.loginForm.email = value;
      },
      onChangePassword(value){
        this.loginForm.password = value;
      }
  },
  updated() {
  },
  created() {
    this.showloader = true;
    CometChat.getLoggedinUser().then(
      (user) => {
        if (user) {
          location.href = "/";
          this.uid = user.getUid();
        } else {
          this.showloader = false;
        }
      },
      (error) => {
        console.log("Error in [getLoggedinUser]", error);
        this.showloader = false;
      }
    );
  },
};
</script>

<style>
.error-message {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.error-message .error-text {
  font-size: 12px;
  text-align: left;
  color: red;
  padding-left: 0;
  margin-top: 10px;
}

.submitFormBtn span {
  font-family: 'Inter-Regular', sans-serif !important;
}
</style>